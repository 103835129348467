<template>
  <section class="section">
    <div class="container">
      <div class="page-header">
        <div class="page-header-start">
          <h3>Activity</h3>
        </div>
        <ValidationObserver v-slot="{ handleSubmit }">
          <div class="page-header-end">
            <ul>
              <li>
                <span>Start Date</span>
                <ValidationProvider
                  name="Start Date"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <span
                    ><input
                      class="input"
                      v-model="date1"
                      type="date"
                      placeholder="select start date"
                      :class="{ error: errors[0] }"
                  /></span>
                  <!-- <span style="color: red; font-size: 12px">{{ errors[0] }}</span> -->
                </ValidationProvider>
              </li>
              <li>
                <span>End Date</span>
                <ValidationProvider
                  name="End Date"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <span
                    ><input
                      class="input"
                      v-model="date2"
                      type="date"
                      placeholder="select end date"
                      :class="{ error: errors[0] }"
                  /></span>
                  <!-- <span style="color: red; font-size: 12px">{{ errors[0] }}</span> -->
                </ValidationProvider>
              </li>
            </ul>
            <div class="button-group">
              <button
                @click="handleSubmit(downloadTemplate)"
                class="button sm primary"
              >
                <i class="fad fa-file-excel"></i>Export
              </button>
            </div>
          </div>
        </ValidationObserver>
      </div>
      <table class="table is-fullwidth slide-down">
        <thead>
          <tr>
            <th>Activity</th>
            <th>Description</th>
            <th>Create by</th>
            <th>Point</th>
            <th>Remark</th>
            <th class="is-sm text-center ">Date</th>
          </tr>
        </thead>
        <tbody>
          <tr class="td-row" v-for="(j, index) in allActivity" :key="index">
            <td data-label="Job Title">{{ j.activity }}</td>
            <!-- Job Title -->
            <td data-label="Pubish Date">{{ j.description }}</td>
            <td data-label="User">{{ j.creator }}</td>
            <!-- Pubish Date -->
            <td data-label="Pubish Date">{{ j.point }}</td>
            <td data-label="User">{{ j.remark }}</td>
            <td data-label="Unpubish Date">{{ j.activityDate }}</td>
            <!-- Unpubish Date -->
          </tr>
        </tbody>
      </table>
      <infinite-loading
        v-if="!isLoading"
        spinner="spiral"
        @infinite="infiniteScroll"
      >
      </infinite-loading>
    </div>
  </section>
</template>
<script>
import vClickOutside from "v-click-outside";
import InfiniteLoading from "vue-infinite-loading";
import moment from "moment";
import axios from "axios";
import { getActivityApi } from "@/apis/activity-api";
export default {
  components: { InfiniteLoading },
  data: () => ({
    allActivity: [],
    page: 0,
    perPage: 10,
    date1: "",
    date2: "",
    isLoading: true,
  }),
  directives: {
    clickOutside: vClickOutside.directive,
  },
  methods: {
    async downloadTemplate() {
      try {
        const date1 = moment(this.date1).format("YYYY-MM-DD");
        const date2 = moment(this.date2).format("YYYY-MM-DD");
        await this.$store.dispatch("loading");
        axios.defaults.headers[
          "Authorization"
        ] = this.$store.getters.getCompanyToken;
        const res = await axios.get(
          `${process.env.VUE_APP_HTTPS_ADMIN}/client-api/export-activity?date1=${date1}&date2=${date2}&token=${this.$store.getters.getCompanyToken}`,
          {
            responseType: "blob",
          }
        );
        const url = URL.createObjectURL(
          new Blob([res.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Activity.xlsx");
        document.body.appendChild(link);
        link.click();
        await this.$store.dispatch("completed");
      } catch (err) {
        await this.$store.dispatch("error");
        throw new Error(err);
      }
    },
    async infiniteScroll($state) {
      setTimeout(async () => {
        this.page++;
        const data = await getActivityApi(this.page, this.perPage);
        let getData = data;
        if (getData.length > 1) {
          this.allActivity.push(...getData);
          $state.loaded();
        } else {
          $state.complete();
        }
      }, 800);
    },
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 300);
  },
};
</script>

<style lang="scss" scoped>
input.error {
  border-width: 1px !important;
  border-style: solid !important;
  border-color: var(--alert-color) !important;
  background-color: rgba(var(--alert-color), 0.15) !important;
  color: var(--alert-color);
}

.page-header-end {
  margin-left: auto;
  display: flex;
  align-items: center;

  ul {
    list-style-type: none;
    margin: 0;
    display: flex;
    align-items: center;

    li {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0%;
      flex-grow: 1;
      margin-left: 10px;

      span {
        display: block;
        flex-grow: 1;

        &:first-child {
          margin-right: 10px;
        }

        input.input {
          font-size: var(--sm-font);
          padding: 0 10px;
          background-color: #fff;
          border: 1px solid var(--border-color);
        }
      }
    }
  }
}
</style>
