import gql from 'graphql-tag'

export const GET_ACTIVITY = gql`
query(
	$page:Int
	$perPage:Int
){
	getActivity(page: $page perPage:$perPage){
		info{
		_id
		activity
		employerId
		companyName
		creator
		point
        activityDate
        description
		remark
	}
		total
	}
}

`

