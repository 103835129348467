import apolloClient from "@/services/apollo-client-company";
import { GET_ACTIVITY } from "@/graphql/Activity";
export const getActivityApi = (page,perPage) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await apolloClient.query({
                query: GET_ACTIVITY,
                variables: {
                    page,
                    perPage,
                  },
            })
            resolve(res.data.getActivity.info)
        } catch (e) {
            reject(e)
        }
    })
}

